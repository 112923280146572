<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
    GridLinkComponent
  },
  data() {
    return {
      site: 29,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<template>

  <ITCGridComponent colorBg="#FF8200" :columnas="3" titulo="Acceso para ciudadanos" :data="[
    {
      desc: null,
      icon: 'ptn-i-documento',
      target: '_self',
      title: 'Guia de Trámites',
      url: 'https://tramite.sanjuan.gob.ar/tramite/org/65',
    },
    {
      desc: null,
      icon: 'map',
      target: '_self',
      title: 'Descarga de Archivos y Cartografía',
      url: 'http://servicioscatastro.sanjuan.gob.ar/descargas/index.php',
    },
    {
      desc: null,
      icon: 'ptn-i-documento',
      target: '_self',
      title: 'Consulta de Expedientes',
      url: 'http://servicioscatastro.sanjuan.gob.ar/dgcconsultasweb',
    },
    {
      desc: null,
      icon: 'pin_drop',
      target: '_self',
      title: 'Consulta Parcelaria Acceso Público',
      url: 'http://servicioscatastro.sanjuan.gob.ar/DgcConsultasWeb/MapApp/DgcPublico?=',
    },

    {
      desc: null,
      icon: 'find_in_page',
      target: '_self',
      title: 'Digesto Online',
      url: 'http://servicioscatastro.sanjuan.gob.ar/DigestoDGC/',
    },
    {
      desc: null,
      icon: 'ptn-i-documento',
      target: '_blank',
      title: 'Solicitud de Certificado de Zona de Frontera',
      url: 'https://forms.gle/YCj2pVVXVL9LpyZK9',
    },
  ]" />

  <ITCGridComponent colorBg="#FF8200" :columnas="3" titulo="Acceso para profesionales" :data="[
    {
      desc: null,
      icon: 'sim_card_download',
      target: '_self',
      title: 'Descarga de Planos de Mensura',
      url: 'http://servicioscatastro.sanjuan.gob.ar/planos/seguridad/Login.php?PageFrom=%2Fplanos%2Findex.php%3F',
    },
    {
      desc: null,
      icon: 'contact_page',
      target: '_self',
      title: 'Consulta Parcelaria con Usuario y Clave',
      url: 'http://servicioscatastro.sanjuan.gob.ar/dgcconsultasweb',
    },
    {
      desc: null,
      icon: 'ptn-i-documento',
      target: '_self',
      title: 'Inscripción de Mensuras para Posesión',
      url: 'http://servicioscatastro.sanjuan.gob.ar/mensuras/seguridad/Login.php?PageFrom=%2Fmensuras%2Findex.php%3F',
    },
  ]" />

  <!-- seccion info institucional -->
  <section>
    <div class="container">
      <div class="row">
        <div class="col-12" style="text-align: justify">
          <h2 class="pb-3">Institucional</h2>
          <h3>Misión:</h3>
          <p class="pb-3 justify-content-between">
            La Misión de la Dirección de Geodesia y Catastro de la Provincia de
            San Juan es garantizar el adecuado ordenamiento y registro
            parcelario, abarcando los aspectos geométricos, jurídicos y
            económicos de los inmuebles existentes, cualquiera sea su naturaleza
            y destino. Es decir, se centra en proporcionar información precisa y
            actualizada sobre la propiedad inmobiliaria, promoviendo la
            transparencia, la equidad y el desarrollo sostenible en el
            territorio provincial.
          </p>
          <h3>Visión:</h3>
          <p>
            Nuestra visión es ser líder en la generación, gestión y difusión de
            información parcelaria, brindando un servicio preciso, claro, ágil,
            accesible y distinguido. Nos comprometemos a proporcionar soluciones
            eficientes y tecnológicamente avanzadas que faciliten las
            tramitaciones, impulsen el desarrollo sostenible y protejan el
            patrimonio territorial. Buscamos ser reconocidos por nuestra
            excelencia profesional, innovación continua y contribución al avance
            del catastro territorial, en estrecha colaboración con diversos
            actores, fomentando la transparencia y la equidad en beneficio de la
            sociedad y el desarrollo de nuestras comunidades.
          </p>
        </div>
        <div class="container">
          <div class="d-flex justify-content-center">
            <video width="640" height="360" controls>
              <source src="./assets/img/MesadeEntrada.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!----Fin sección institucional---->

  <section>
    <h2 class="container">Accesos de Interés</h2>
    <BoxIconGridComponent class="p-0 pb-5" columnas="2" :data="[
    {
      desc: null,
      icon: 'public',
      target: '_blank',
      title: 'Instituto Geográfico Nacional',
      url: 'https://www.ign.gob.ar/',
    },
    {
      desc: null,
      icon: 'groups',
      target: '_blank',
      title: 'Col. de Profesionales de la Agrimensura de San Juan',
      url: 'http://www.agrimensoressanjuan.org.ar/',
    }
    ]" />
  </section>

  <FrecuentAccessComponent :data="[
    {
      title: 'Atención a la Ciudadanía',
      links: [
        {
          target: '_blank',
          title: '4305360',
          url: 'tel:4305360',
        },
        {
          target: '_blank',
          title: '4307427',
          url: 'tel:4307427',
        },
        {
          target: '_blank',
          title: '4305361',
          url: 'tel:4305361',
        },
      ],
    },
    {
      title: 'Enlace con el Poder Judicial',
      links: [
        {
          target: '_blank',
          title: 'oficiosdgc@sanjuan.gov.ar',
          url: 'mailto:oficiosdgc@sanjuan.gov.ar',
        }
      ],
    },
    {
      title: 'Solicitud de Copia de Planos para Público en General y Profesionales',
      links: [
        {
          target: '_blank',
          title: 'entregaplano.dgc@sanjuan.gov.ar',
          url: 'mailto:entregaplano.dgc@sanjuan.gov.ar',
        }
      ],
    },
    {
      title: 'Enlace con Escribanía Mayor de Gobierno y Escribanía  del Instituto Provincial de la Vivienda.',
      links: [
        {
          target: '_blank',
          title: 'recepcion.catastro@sanjuan.gov.ar ',
          url: 'mailto:recepcion.catastro@sanjuan.gov.ar ',
        }
      ],
    }
  ]" />

  <NewsComponent />

  <ITCGridComponent :columnas="4" titulo="Consultas y Trámites" :data="[
    {
      desc: null,
      icon: 'ptn-i-documento',
      target: '_self',
      title: 'Consulta de Expedientes',
      url: '?uid=CUMV-VIEW-ExpedientesComponent',
    },
    {
      desc: null,
      icon: 'ptn-i-acuerdo',
      target: '_self',
      title: 'Contrataciones',
      url: 'https://compraspublicas.sanjuan.gob.ar/',
    },
    {
      desc: null,
      icon: 'note',
      target: '_self',
      title: 'Trámites',
      url: 'https://tramite.sanjuan.gob.ar/principal/listar',
    },
    {
      desc: null,
      icon: 'forum',
      target: '_self',
      title: 'Consultas y Solicitudes',
      url: '?uid=CUMV-VIEW-FormComponent',
    },
  ]" />

  <GridLinkComponent :columnas="4" titulo="Links Útiles" :data="[
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Ministerio de Economía, Hacienda y Finanzas',
      url: 'https://hacienda.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Dirección de Gobierno Digital',
      url: 'https://gobiernoabierto.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Dirección Provincial de Informática',
      url: 'https://dpi.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Dirección de Ciberseguridad',
      url: 'https://ciberseguridad.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title:
        'Dirección de Recursos Humanos y Organización del Empleo Público',
      url: 'https://rrhh.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'IIEE - Instituto de Investigaciones Económicas y Estadísticas',
      url: 'https://web.sanjuan.gob.ar/iiee/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Ciudadano Digital',
      url: 'https://cidi.sanjuan.gob.ar/',
    },
    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Innova',
      url: 'http://innovak8s.sanjuan.gob.ar/',
    },

    {
      subtitle: 'Página web',
      target: '_blank',
      title: 'Compras Públicas',
      url: 'https://compraspublicas.sanjuan.gob.ar/',
    },
  ]" />
</template>
